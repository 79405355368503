function activateTypeahead() {

  if ($('#resource_author_name').length > 0) {
    const authors = new Bloodhound({
      datumTokenizer: (datum) => {
        return Bloodhound.tokenizers.whitespace(datum.name);
      },
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      prefetch: {
        cache: false,
        url: '/authors.json',
        filter: function(authors) {
          return $.map(authors, (author) => {
            return {
              id: author.id,
              name: author.name
            };
          });
        }
      }
    });

    return $('#resource_author_name').typeahead({
      highlight: true
    }, {
      displayKey: 'name',
      source: authors.ttAdapter()
    });
  }

}

export default activateTypeahead
