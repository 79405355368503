function scrollTop() {
  //browser window scroll (in pixels) after which the "back to top" link is shown
  const offset = 300
  const offset_fade = 1200
  const $scroll_to_top = $('.scroll-top')


  // hide or show the "back to top" link
  $(window).scroll(() => {
    if ($(window).scrollTop() > offset_fade) {
      return $scroll_to_top.addClass('scroll-top-fade')
    } else if ($(window).scrollTop() > offset) {
      return $scroll_to_top.addClass('scroll-top-visible')
    } else {
      // this changes back to 100% opacity when scrolling up past offset_fade
      // $scroll_to_top.removeClass('scroll-top-fade')
      return $scroll_to_top.removeClass('scroll-top-visible scroll-top-fade')
    }
  })

  // scroll to top
  $scroll_to_top.on('click', (event) => {
    event.preventDefault()
    $('body, html').animate({
      scrollTop: 0
    }, 700)
    if (typeof gtag != 'undefined') gtag('event', 'Scroll to top', { 'event_category': 'ui', 'event_label': document.title });
  })
}

export default scrollTop
