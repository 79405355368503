function activateSelect2() {

  $("#resource_topic_ids").select2({
    theme: "bootstrap"
  });

  if ($('#prayer_request_prayer_request_category_name').length > 0) {
    return $("#prayer_request_prayer_request_category_name").select2({
      theme: "bootstrap",
      tags: true
    });
  }
}

export default activateSelect2
