import 'animate.css/animate.min.css'
import Swal from 'sweetalert2'

function cart() {
  $(".cash_check_button").click((event) => {
    event.preventDefault()

    return Swal.fire({
      title: "Pay by Cash/Check",
      text: "Your order will be marked as un-paid and you will need to bring payment with you when picking-up your order.",
      icon: "info",
      showCancelButton: true,
      confirmButtonText: "I agree"
    }).then((result) => {
      if (result.value) {
        Swal.fire({
          title: "Processing...",
          text: "Your order is being processsed. Please wait a moment.",
          icon: "success",
        })
        Turbolinks.visit("/cart/skip_payment")
      }
    })
  })

}

export default cart
