function scrollToNextSection() {
  const $scroll_to_next = $('.scroll-to-next-section')

  $scroll_to_next.on('click', (event) => {
    event.preventDefault()
    const $next_section_top = $scroll_to_next.closest("section").next("section").offset().top - $("nav.navbar").outerHeight()

    $('body,html').animate({
      scrollTop: $next_section_top
    }, 700)
    if (typeof gtag != 'undefined') gtag('event', 'Scroll down', { 'event_category': 'ui', 'event_label': $scroll_to_next.closest("section").attr('class') });
  })

}

export default scrollToNextSection
