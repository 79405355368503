/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// import forms from 'forms'
import activatePrayerRequestSubmissionForm from 'activatePrayerRequestSubmissionForm'
import activateSelect2 from 'activateSelect2'
import activateTypeahead from 'activateTypeahead'
import cart from 'cart'
import eventAllDayForm from 'eventAllDayForm'
import nestedFormFields from 'nestedFormFields'
import scrollToNextSection from 'scrollToNextSection'
import scrollTop from 'scrollTop'

const close_modal = (selector) => {
  return $(".modal").modal("hide")
}

const modal = (content) => {
  close_modal()
  let modal_content = $(content)
  $("body").append(modal)
  $("body").css("overflow", "hidden")
  modal_content.filter(".modal").modal()
  modal_content.on("hidden", function() {
    $("body").css("overflow", "auto")
    return $(this).remove()
  })
  return modal_content
}

const activate_datepickers = () => {
  return $(".jq_enable_datepicker").datepicker({
    dateFormat: "yy-mm-dd"
  })
}

const ready = () => {
  activate_datepickers()
  window.soundManager.html5PollingInterval = 500
  window.soundManager.flashPollingInterval = 500
  window.soundManager.stopAll()

  activateSelect2()
  activateTypeahead()
  cart()
  eventAllDayForm()
  nestedFormFields()
  scrollTop()
  scrollToNextSection()

  if ($("#relationship .question").length > 0) {
    return activatePrayerRequestSubmissionForm();
  }
  return true
}

$(document).on("turbolinks:load", (event) => {
  return ready()
})
