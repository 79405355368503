function eventAllDayForm() {

  if ($("#event_is_all_day").is(':checked')) {
    $("label[for=event_start_time]").parent().hide()
    $("label[for=event_end_time]").parent().hide()
  } else {
    $("label[for=event_start_time]").parent().show()
    $("label[for=event_end_time]").parent().show()
  }

  return $("#event_is_all_day").change( function() {
    if ($(this).is(':checked')) {
      $("label[for=event_start_time]").parent().hide()
      return $("label[for=event_end_time]").parent().hide()
    } else {
      $("label[for=event_start_time]").parent().show()
      return $("label[for=event_end_time]").parent().show()
    }
  })

}

export default eventAllDayForm
