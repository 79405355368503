function activatePrayerRequestSubmissionForm() {

  let prayer_request = {
    name: "",
    request: "",
    requested_by: "",
    relationship: "",
    requested_by_relationship: "",
    permission: false,
    confidential: false,
    how_question: "How can we be praying?",
    who_question: "What is the person's name?",
    success_message: "We'll be praying over this situation"
  }

  const show_permission = () => {
    return $("#permission").removeClass("hidden")
  }

  const show_denied = () => {
    return $("#denied").removeClass("hidden")
  }

  const show_confidential = () => {
    return $("#confidential").removeClass("hidden")
  }

  const show_name = () => {
    $("#name h2").text(prayer_request.who_question)
    return $("#name").removeClass("hidden")
  }

  const show_request = () => {
    $("#request h2").text(prayer_request.how_question)
    return $("#request").removeClass("hidden")
  }

  const show_requested_by = () => {
    return $("#requested_by").removeClass("hidden")
  }

  const show_requested_by_relationship = () => {
    $("#requested_by_relationship h2 span.name").text(prayer_request.name)
    return $("#requested_by_relationship").removeClass("hidden")
  }

  const show_requested_by_email = () => {
    return $("#requested_by_email").removeClass("hidden")
  }

  const show_requested_by_email_error = () => {
    return $("#requested_by_email .error").removeClass("hidden")
  }

  const show_success = () => {
    $("#success p").text(prayer_request.success_message)
    return $("#success").removeClass("hidden")
  }

  const show_error = () => {
    $("#error").removeClass("hidden")
    $("#requested_by_email .question").removeClass("hidden")
    return $("#requested_by_email .answer").addClass("hidden")
  }

  const hide_relationship = () => {
    $("#relationship .question").addClass("hidden")
    $("#relationship .answer p").text(prayer_request.relationship)
    return $("#relationship .answer").removeClass("hidden")
  }

  const hide_permission = () => {
    $("#permission .question").addClass("hidden")
    $("#permission .answer p").text(prayer_request.permission)
    return $("#permission .answer").removeClass("hidden")
  }

  const hide_denied = () => {
    return $("#denied .question").addClass("hidden")
  }

  const hide_confidential = () => {
    $("#confidential .question").addClass("hidden")
    $("#confidential .answer p").text(prayer_request.confidential)
    return $("#confidential .answer").removeClass("hidden")
  }

  const hide_name = () => {
    $("#name .question").addClass("hidden")
    $("#name .answer p").text(prayer_request.name)
    return $("#name .answer").removeClass("hidden")
  }

  const hide_requested_by_relationship = () => {
    $("#requested_by_relationship .question").addClass("hidden")
    $("#requested_by_relationship .answer p").text(prayer_request.requested_by_relationship)
    return $("#requested_by_relationship .answer").removeClass("hidden")
  }

  const hide_request = () => {
    $("#request .question").addClass("hidden")
    $("#request .answer p").text(prayer_request.request)
    return $("#request .answer").removeClass("hidden")
  }

  const hide_requested_by = () => {
    $("#requested_by .question").addClass("hidden")
    $("#requested_by .answer p").text(prayer_request.requested_by)
    return $("#requested_by .answer").removeClass("hidden")
  }

  const hide_requested_by_email = () => {
    $("#requested_by_email .question").addClass("hidden")
    $("#requested_by_email .answer p").text(prayer_request.requested_by_email)
    return $("#requested_by_email .answer").removeClass("hidden")
  }

  const hide_requested_by_email_error = () => {
    return $("#requested_by_email .error").addClass("hidden")
  }

  const hide_error = () => {
    return $("#error").addClass("hidden")
  }

  const is_valid_email = (email) => {
    return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)
  }

  const submit_request = () => {
    var prayer_request_data
    prayer_request_data = {
      prayer_request: {
        name: prayer_request.name,
        request: prayer_request.request,
        requested_by: prayer_request.requested_by,
        requested_by_email: prayer_request.requested_by_email,
        requested_by_relationship: prayer_request.requested_by_relationship,
        confidential: prayer_request.confidential === "Yes"
      }
    }
    return $.post("/prayer_requests/submission", prayer_request_data, function(data) {
      return console.log(data)
    }).done(() => {
      show_success()
      return hide_error()
    }).fail(() => {
      return show_error()
    })
  }

  $("#relationship button").click(function(e) {
    prayer_request.relationship = $(e.target).data("relationship")
    hide_relationship()
    if (prayer_request.relationship === "Myself") {
      prayer_request.who_question = "What is your name?"
      return show_confidential()
    } else if (prayer_request.relationship === "Friend") {
      return show_permission()
    } else {
      return show_confidential()
    }
  })

  $("#permission button").click(function(e) {
    prayer_request.permission = $(e.target).data("permission")
    hide_permission()
    if (prayer_request.permission === "Yes") {
      return show_confidential()
    } else {
      return show_denied()
    }
  })

  $("#confidential button").click(function(e) {
    prayer_request.confidential = $(e.target).data("confidential")
    hide_confidential()
    if (prayer_request.relationship === "Situation") {
      return show_request()
    } else {
      return show_name()
    }
  })

  $("#name form").on("submit", function(e) {
    e.preventDefault()
    prayer_request.name = $("#prayer_request_name").val()
    if (prayer_request.relationship === "Myself") {
      prayer_request.requested_by = $("#prayer_request_name").val()
      prayer_request.how_question = "How can we be praying for you?"
      prayer_request.success_message = "We'll be praying for you"
    } else if (prayer_request.relationship === "Family" || prayer_request.relationship === "Friend") {
      prayer_request.how_question = `How can we be praying for ${prayer_request.name}?`
      prayer_request.success_message = `We'll be praying for ${prayer_request.name}`
    }
    if (prayer_request.name !== "") {
      hide_name()
      if (prayer_request.relationship === "Friend" || prayer_request.relationship === "Family") {
        show_requested_by_relationship()
      } else {
        show_request()
      }
    }
    return false
  })

  $("#requested_by_relationship form").on("submit", function(e) {
    e.preventDefault()
    prayer_request.requested_by_relationship = $("#prayer_request_requested_by_relationship").val()
    if (prayer_request.requested_by_relationship !== "") {
      hide_requested_by_relationship()
      show_request()
    }
    return false
  })

  $("#request form").on("submit", function(e) {
    e.preventDefault()
    prayer_request.request = $("#prayer_request_request").val()
    if (prayer_request.request !== "") {
      hide_request()
      if (prayer_request.relationship === "Myself") {
        show_requested_by_email()
      } else {
        show_requested_by()
      }
    }
    return false
  })

  $("#requested_by form").on("submit", function(e) {
    e.preventDefault()
    prayer_request.requested_by = $("#prayer_request_requested_by").val()
    if (prayer_request.requested_by !== "") {
      hide_requested_by()
      show_requested_by_email()
    }
    return false
  })

  $("#requested_by_email form").on("submit", function(e) {
    e.preventDefault()
    if (is_valid_email($("#prayer_request_requested_by_email").val())) {
      prayer_request.requested_by_email = $("#prayer_request_requested_by_email").val()
      hide_requested_by_email()
      hide_requested_by_email_error()
      submit_request()
    } else {
      show_requested_by_email_error()
    }
    return false
  })

}

export default activatePrayerRequestSubmissionForm
